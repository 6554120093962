import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import {useHistory} from "react-router-dom";

const UserInfo = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const myProfile = () => {
    history.push('/profile');
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={myProfile}>My Profile</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      <Avatar src='https://via.placeholder.com/150x150'
              className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  )
};

export default UserInfo;
