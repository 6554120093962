import React from "react";
import {Layout, Popover, notification} from "antd";
import {Link} from "react-router-dom";

import {toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import UserProfile from "containers/Sidebar/UserProfile";

import Auxiliary from "util/Auxiliary";


import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {useDispatch, useSelector} from "react-redux";

import {useHistory} from "react-router-dom";


const {Header} = Layout;

const Topbar = () => {

  const {width, navCollapsed, navStyle} = useSelector(({settings}) => settings);
  const dispatch = useDispatch();

  const history = useHistory();

  const onKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      let value = evt.target.value;
      if(value.length >= 3){
        history.push('/search?query='+evt.target.value);
      }
      else {
        notification.warning({
          message:'Warning',
          description:'Please enter minimum 3 character',
          duration:3
        })
      }
    }
  }
  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
             onClick={() => {
               dispatch(toggleCollapsedSideNav(!navCollapsed));
             }}
          />
        </div> : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={require("assets/images/logo-32x32.png")}/></Link>

     { <SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
                 placeholder="Search in app..."
                 onKeyDown={onKeyDown}
                 />}
      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={
            <SearchBox styleName="gx-popover-search-bar"
                       placeholder="Search in app..."
                      onKeyDown={onKeyDown}
                       />
          } trigger="click">
            <span className="gx-pointer gx-d-block"><i className="icon icon-search-new"/></span>
          </Popover>
        </li>

        {width >= TAB_SIZE ? 
          <Auxiliary>
            <li className='gx-user-nav' style={{fontSize:14}}><UserProfile/></li>
          </Auxiliary>
         :
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo/></li>
          </Auxiliary>
        }
      </ul>
    </Header>
  );
};

export default Topbar;
