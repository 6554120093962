import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
      <Route path={`${match.url}dpp`} component={asyncComponent(() => import('./Dpp_pengurus'))}/>
      <Route path={`${match.url}dpd_organisasi`} component={asyncComponent(() => import('./Dpd/Organisasi'))}/>
      <Route path={`${match.url}dpd_pengurus`} component={asyncComponent(() => import('./Dpd/Pengurus'))}/>
      <Route path={`${match.url}dpc_organisasi`} component={asyncComponent(() => import('./Dpc/Organisasi'))}/>*/}
      <Route path={`${match.url}dpc_pengurus`} component={asyncComponent(() => import('./Dpc/Pengurus'))}/>
      <Route path={`${match.url}pengusaha_perusahaan`} component={asyncComponent(() => import('./Pengusaha_perusahaan'))}/>
      <Route path={`${match.url}pengusaha_pengurus`} component={asyncComponent(() => import('./Pengusaha_pengurus'))}/>
      <Route path={`${match.url}pengusaha_kendaraan`} component={asyncComponent(() => import('./Pengusaha_kendaraan'))}/>
      <Route path={`${match.url}pengusaha_pengemudi`} component={asyncComponent(() => import('./Pengusaha_pengemudi'))}/>
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('./Profile'))}/>
      <Route path={`${match.url}notification`} component={asyncComponent(() => import('./Notification'))}/>
      <Route path={`${match.url}search`} component={asyncComponent(() => import('./Search'))}/>
      <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>
      <Route path={`${match.url}dpp_info`} component={asyncComponent(() => import('./Info/Dpp'))}/>
      <Route path={`${match.url}dpd_info`} component={asyncComponent(() => import('./Info/Dpd'))}/>
      <Route path={`${match.url}dpc_info`} component={asyncComponent(() => import('./Info/Dpc'))}/>
      <Route path={`${match.url}pengusaha_info`} component={asyncComponent(() => import('./Info/Pengusaha'))}/>
      <Route path={`${match.url}dpp_panduan`} component={asyncComponent(() => import('./Panduan/Dpp'))}/>
      <Route path={`${match.url}dpd_panduan`} component={asyncComponent(() => import('./Panduan/Dpd'))}/>
      <Route path={`${match.url}dpc_panduan`} component={asyncComponent(() => import('./Panduan/Dpc'))}/>
      <Route path={`${match.url}pengusaha_panduan`} component={asyncComponent(() => import('./Panduan/Pengusaha'))}/>
    </Switch>
  </div>
);

export default App;
