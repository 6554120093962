import React from "react";
import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  THEME_TYPE_LITE,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {

  const {authUser} = useSelector(({auth}) => auth);

  let {navStyle,themeType, pathname} = useSelector(({settings}) => settings);

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  // console.log(authUser);

  const roleTmp = {
    'superadmin':['dpp','dpd','dpc','pengusaha','pengemudi'],
    'dpp':['dpp','dpd','dpc','pengusaha','pengemudi','dpp_info','dpp_panduan'],
    'dpp-anggota':['dpp','dpd','dpc','pengusaha','pengemudi','dpp_info','dpp_panduan'],
    'dpd':['dpd','dpc','pengusaha','pengemudi','dpd_info','dpd_panduan'],
    'dpd-anggota':['dpd','dpc','pengusaha','pengemudi','dpd_info','dpd_panduan'],
    'dpc':['dpc','pengusaha','pengemudi','notification','dpc_info','dpc_panduan'],
    'dpc-anggota':['dpc','pengusaha','pengemudi','notification','dpc_info','dpc_panduan'],
    'pengusaha':['pengusaha','pengemudi','notification','pengusaha_info','pengusaha_panduan'],
    'karyawan':['pengusaha','pengemudi','pengusaha_info','pengusaha_panduan'],
    'karyawan-anggota':['pengusaha','pengemudi','pengusaha_info','pengusaha_panduan'],
    'pengemudi':[],
  };

  const hideMenu =  (menu) => {
    let user_type = authUser.user_type ? authUser.user_type.toLowerCase() : null;
    if(typeof roleTmp[user_type] === 'undefined') return true;

    return roleTmp[user_type].indexOf(menu) < 0 ? true : false;
  }

  return (<>

      <SidebarLogo/>
      <div className="gx-sidebar-content">
        <CustomScrollbars autoHeight={true} autoHeightMin={600} className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <MenuItemGroup className="gx-menu-group" title={<IntlMessages id="sidebar.main"/>}>
              <SubMenu
                key="dpp"
                hidden={hideMenu('dpp')}
                className={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-widgets"/>
                   <span>DPP</span>
                  </span>
                }>
                <Menu.Item key="dpp_pengurus">
                  <Link to="/dpp"><i className="icon icon-widgets"/>
                  <span>Data Pengurus</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="dpd"
                hidden={hideMenu('dpd')}
                className={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-widgets"/>
                   <span>DPD</span>
                  </span>
                }>
                <Menu.Item key="dpd_organisasi">
                  <Link to="/dpd_organisasi"><i className="icon icon-widgets"/>
                    <span>
                      Data Organisasi
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="dpd_pengurus">
                  <Link to="/dpd_pengurus"><i className="icon icon-widgets"/>
                    <span>
                      Data Pengurus
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                hidden={hideMenu('dpc')}
                key="dpc"
                className={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-widgets"/>
                   <span>DPC</span>
                  </span>
                }>
                <Menu.Item key="dpc_organisasi">
                  <Link to="/dpc_organisasi"><i className="icon icon-widgets"/>
                    <span>
                      Data Organisasi
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="dpc_pengurus">
                  <Link to="/dpc_pengurus"><i className="icon icon-widgets"/>
                    <span>
                      Data Pengurus
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="pengusaha"
                hidden={hideMenu('pengusaha')}
                className={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-widgets"/>
                   <span>Pengusaha</span>
                  </span>
                }>
                 {/*<SubMenu
                  key="pengusaha_perusahaan"
                  className={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <Link to="/pengusaha_perusahaan">
                    <span>
                      <i className="icon icon-widgets"/>
                     <span>Data Perusahaan</span>
                    </span>
                    </Link>
                  }>*/}
                    {/*<Menu.Item key="pengusaha_pengemudi">
                      <Link to="/pengusaha_pengemudi"><i className="icon icon-widgets"/>
                        <span>Data Pengemudi</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>*/}
                {<Menu.Item key="pengusaha_perusahaan">
                  <Link to="/pengusaha_perusahaan"><i className="icon icon-widgets"/>
                    <span>Data Perusahaan</span>
                  </Link>
                </Menu.Item>}
                <Menu.Item key="pengusaha_pengurus">
                  <Link to="/pengusaha_pengurus"><i className="icon icon-widgets"/>
                    <span>Data Pengurus</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="pengusaha_kendaraan">
                  <Link to="/pengusaha_kendaraan"><i className="icon icon-widgets"/>
                    <span>Data Kendaraan</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              {<Menu.Item
                hidden={hideMenu('pengemudi')}
                key="pengemudi">
                <Link to="/pengusaha_pengemudi"><i className="icon icon-widgets"/>
                  <span>
                    <IntlMessages id="sidebar.pengemudi"/>
                  </span>
                </Link>
              </Menu.Item>}

              <Menu.Item
               hidden={hideMenu('notification')}
               key="notification">
                <Link to="/notification"><i className="icon icon-widgets"/>
                  <span>Notification</span>
                </Link>
              </Menu.Item>
            </MenuItemGroup>

            <SubMenu
              key="info_panduan"
              className={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  <i className="icon icon-widgets"/>
                 <span>Info & Panduan</span>
                </span>
              }>
              <Menu.Item hidden={hideMenu('dpp_info')} key="dpp_info">
                <Link to="/dpp_info"><i className="icon icon-widgets"/>
                  <span>
                    Info
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item hidden={hideMenu('dpd_info')} key="dpd_info">
                <Link to="/dpd_info"><i className="icon icon-widgets"/>
                  <span>
                    Info
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item hidden={hideMenu('dpc_info')} key="dpc_info">
                <Link to="/dpc_info"><i className="icon icon-widgets"/>
                  <span>
                    Info
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item hidden={hideMenu('pengusaha_info')} key="pengusaha_info">
                <Link to="/pengusaha_info"><i className="icon icon-widgets"/>
                  <span>
                    Info
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item hidden={hideMenu('dpp_panduan')} key="dpp_panduan">
                <Link to="/dpp_panduan"><i className="icon icon-widgets"/>
                  <span>
                    Panduan
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item hidden={hideMenu('dpd_panduan')} key="dpd_panduan">
                <Link to="/dpd_panduan"><i className="icon icon-widgets"/>
                  <span>
                    Panduan
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item hidden={hideMenu('dpc_panduan')} key="dpc_panduan">
                <Link to="/dpc_panduan"><i className="icon icon-widgets"/>
                  <span>
                    Panduan
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item hidden={hideMenu('pengusaha_panduan')} key="pengusaha_panduan">
                <Link to="/pengusaha_panduan"><i className="icon icon-widgets"/>
                  <span>
                    Panduan
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>            


          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;

