import React,{useState} from "react";
import {Button, Form, Input, Modal, Spin} from "antd";
import IntlMessages from "util/IntlMessages";

import {useHistory,Link} from "react-router-dom";


import axios from "util/Api";

const FormItem = Form.Item;

const ForgotPassword = (props) => {

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        let val = {
          ...values,
          phone:'0'+values.phone
        }
        setLoading(true)
        axios.post('/reset-password',val).then(res => {
          let {
            data,
            error,
            success
          } = res.data;

          if(success){
            Modal.info({
              title:"Success",
              content:"Plase check your email!",
              onOk:function(){
                history.push('/signin')
              }
            });
          }
          else{
            let error_message = "Something error with server";
            // console.log(error)
            if(typeof error !== 'undefined' && error.length > 0){
              error_message = '';
              error.map(err => {
                error_message += err+' \n'
              });
            }

            Modal.error({
              title:"Error",
              content:error_message
            });
            setLoading(false);
          }
        }).catch(function(error){
          setLoading(false)
        });
      }
    });
  };

  const phoneChange = (value) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      props.form.setFieldValues({
        email:value
      });
    }
  }

  const [loading,setLoading] = useState(false);
  const {getFieldDecorator} = props.form;

  return (
    <div className="gx-login-container">
      <Spin spinning={loading} tip="Loading ...">
      <div className="gx-login-content">

        <div className="gx-login-header">
          <img className="gx-mb-2" src={require("assets/images/logo-organda.png")} alt="wieldy" title="wieldy" width={50}/>
        </div>
        <div className="gx-mb-4">
          <h2>Forgot Your Password ?</h2>
          <p>Don't worry. Recovering the password is easy.Just tell us the email you have registered</p>
        </div>

        <Form layout="vertical" onSubmit={handleSubmit} className="gx-login-form gx-form-row0">

          <FormItem>
            {getFieldDecorator('phone', {
              rules: [{
                required: true, message: 'Please input your mobile number',
              }],
            })(
              <Input addonBefore='+62' type="number" placeholder="Phone number"/>
            )}
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="app.userAuth.send"/>
            </Button>
            <Link to="/signin" className="gx-login-form-forgot">Back to home</Link>
          </FormItem>
        </Form>
      </div>
      </Spin>
    </div>
  );
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default (WrappedForgotPasswordForm);
