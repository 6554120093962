import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input, Icon} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {userSignIn} from "../appRedux/actions/Auth";
// import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  const remember_me = JSON.parse(localStorage.getItem('myorganda_remember'));

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      console.log(values)
      if (!err) {
        dispatch(userSignIn(values));
      }
    });
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token, props.history]);

  const {getFieldDecorator} = props.form;

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
              <img alt="" src={require('assets/images/logo-w.png')} width={350} className="gx-login-image-white"/>
            <div className="gx-login-container">
              <div className="gx-login-content">
                <div className="gx-login-header gx-text-center">
                  <div className="gx-login-header-green-background">
                    <Icon type="user" className="gx-login-header-icon-user"/>
                  </div>
                  <h1 className="gx-login-title">Login</h1>
                </div>
                <Form onSubmit={handleSubmit} className="gx-signin-form gx-form-row0 gx-login-form-extra">
                  <FormItem>
                    {getFieldDecorator('email', {
                      initialValue:remember_me ? remember_me.username : null,
                      rules: [{
                        required: true, message: 'Please input your username!'
                      }],
                    })(
                      <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                             placeholder="Username"/>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('password', {
                      initialValue:remember_me ? remember_me.password : null,
                      rules: [{
                        required: true, message: 'Please input your Password!'
                      }],
                    })(
                      <Input prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                             type="password"
                             placeholder="Password"/>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('remember', {
                      initialValue:remember_me ? true : false,
                      valuePropName: 'checked'
                    })(
                      <Checkbox>Remember me</Checkbox>
                    )}
                    <Link to="/forgotpassword" className="gx-login-form-forgot" style={{float:'right',marginRight:10}}>Forgot password</Link>
                  </FormItem>
                  <FormItem className="gx-text-center">
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
          {<InfoView/>}
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
