import React from "react";
import {useDispatch,useSelector} from "react-redux";
import {Avatar, Popover, Modal} from "antd";
import {userSignOut} from "../../appRedux/actions/Auth";
import {useHistory} from "react-router-dom";


const UserProfile = () => {
  const dispatch = useDispatch();
  const {authUser} = useSelector(({auth}) => auth);
  const {userAccess} = useSelector(({settings}) => settings)
  const history = useHistory();

  const {confirm} = Modal;

  const myProfile = () => {
    history.push('/profile');
  }

  const handleLogout = () => {
      confirm({
          title: 'Logout',
          content: 'Are you sure want to logout?',
          onOk() {
              dispatch(userSignOut());
          }
      });
  };


  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={myProfile}>My Profile</li>
      <li onClick={handleLogout}>Logout
      </li>
    </ul>
  );

  const AvatarImage = () => {
    let avatar = require("assets/images/user-150x150.png");
    // console.log(authUser)
    if(typeof authUser.job_profile !== 'undefined' && typeof authUser.job_profile.photo !== 'undefined')
      avatar = authUser.job_profile.photo

    return (
      <Avatar 
        src={avatar}
        className="gx-size-40 gx-pointer gx-mr-3" 
        alt=""/>
    );
  }

  return (

    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <AvatarImage/>
        <span className="gx-avatar-name">
          {authUser.name} <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>

  )
};

export default UserProfile;
