import axios from 'axios';

import {notification} from "antd";

let apiBaseUrl;

if (process.env.NODE_ENV === 'development') {
    apiBaseUrl = 'http://myorganda.org/api';
}

if (process.env.NODE_ENV === 'production') {
    apiBaseUrl = 'https://myorganda.org/api';
    // apiBaseUrl = 'http://dev.myorganda.org/api';
}

const api =  axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  }
});

api.interceptors.request.use(config => {
    const commonHeaders = {
        ...config.headers.common,
    };  

    let auth = JSON.parse(localStorage.getItem('myorganda'));

    if(auth){
        commonHeaders.Authorization = 'Bearer ' + auth.token;
    }
    
    config.headers.common = commonHeaders;
    return config;
});

api.interceptors.response.use(
    response => {       
        return response;
    },
    error => {
        const data = error.response.data;
        var error_message = error.message;

        if(typeof data.error !== 'undefined' && data.error.length > 0){
          error_message = '';
          data.error.map(err => {
            error_message += err+' \n'
          });
        }

        else if(typeof data.message !== 'undefined' && data.message){
          error_message = data.message
        }

        notification.error({
          message:'Error',
          description:error_message
        });
        
        return Promise.reject(error);
    }
);

export default api
