import {SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH} from "constants/ActionTypes";
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_FIXED,
  THEME_COLOR_SELECTION,
  THEME_COLOR_SELECTION_PRESET,
  THEME_TYPE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";

const initialSettings = {
  navCollapsed: true,
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_LITE,
  colorSelection: THEME_COLOR_SELECTION_PRESET,

  pathname: '',
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  userAccess:{
    "superadmin_admin.dpp_pengurus":["read","create","update","delete","export","import"],
    "superadmin_admin.dpd_organisasi":["read","create","update","delete","export","import"],
    "superadmin_admin.dpd_pengurus":["read","create","update","delete","export","import"],
    "superadmin_admin.dpc_organisasi":["read","create","update","delete","export","import"],
    "superadmin_admin.dpc_pengurus":["read","create","update","delete","export","import"],
    "superadmin_admin.pengusaha_perusahaan":["read","export"],
    "superadmin_admin.pengusaha_pengurus":["read","export"],
    "superadmin_admin.pengusaha_pengemudi":["read","export"],
    "superadmin_admin.pengusaha_kendaraan":["read","export"],

    "dpp.dpp_pengurus":["read","create","update","delete","export","import"],
    "dpp.dpd_organisasi":["read","create","update","delete","export","import"],
    "dpp.dpd_pengurus":["read","create","update","delete","export","import"],
    "dpp.dpc_organisasi":["read","create","update","delete","export","import"],
    "dpp.dpc_pengurus":["read","create","update","delete","export","import"],
    "dpp.pengusaha_perusahaan":["read","export"],
    "dpp.pengusaha_pengurus":["read","export"],
    "dpp.pengusaha_pengemudi":["read","export"],
    "dpp.pengusaha_kendaraan":["read","export"],

    "dpp-anggota.dpp_pengurus":["read","export"],
    "dpp-anggota.dpd_organisasi":["read","export"],
    "dpp-anggota.dpd_pengurus":["read","export"],
    "dpp-anggota.dpc_organisasi":["read","export"],
    "dpp-anggota.dpc_pengurus":["read","export"],
    "dpp-anggota.pengusaha_perusahaan":["read","export"],
    "dpp-anggota.pengusaha_pengurus":["read","export"],
    "dpp-anggota.pengusaha_pengemudi":["read","export"],
    "dpp-anggota.pengusaha_kendaraan":["read","export"],

    "dpd.dpd_organisasi":["read","update","export"],
    "dpd.dpd_pengurus":["read","create","update","delete","export","import"],
    "dpd.dpc_organisasi":["read","create","update","delete","export","import"],
    "dpd.dpc_pengurus":["read","create","update","delete","export","import"],
    "dpd.pengusaha_perusahaan":["read","create","update","delete","export","import"],
    "dpd.pengusaha_pengurus":["read","export"],
    "dpd.pengusaha_kendaraan":["read","export"],
    "dpd.pengusaha_pengemudi":["read","export"],
    
    "dpd-anggota.dpd_organisasi":["read","export"],
    "dpd-anggota.dpd_pengurus":["read","export"],
    "dpd-anggota.dpc_organisasi":["read","export"],
    "dpd-anggota.dpc_pengurus":["read","export"],
    "dpd-anggota.pengusaha_perusahaan":["read","export"],
    "dpd-anggota.pengusaha_pengurus":["read","export"],
    "dpd-anggota.pengusaha_pengemudi":["read","export"],
    "dpd-anggota.pengusaha_kendaraan":["read","export"],

    "dpc.dpc_organisasi":["read","update","export"],
    "dpc.dpc_pengurus":["read","create","update","delete","export","import"],
    "dpc.pengusaha_perusahaan":["read","create","update","delete","export","import"],
    "dpc.pengusaha_pengurus":["read","export"],
    "dpc.pengusaha_kendaraan":["read","export"],
    "dpc.pengusaha_pengemudi":["read","export","approve","reject"],
    
    "dpc-anggota.dpc_organisasi":["read","export"],
    "dpc-anggota.dpc_pengurus":["read","export"],
    "dpc-anggota.pengusaha_perusahaan":["read","export"],
    "dpc-anggota.pengusaha_pengurus":["read","export"],
    "dpc-anggota.pengusaha_pengemudi":["read","export"],
    "dpc-anggota.pengusaha_kendaraan":["read","export"],

    "pengusaha.pengusaha_perusahaan":["read","update","export"],
    "pengusaha.pengusaha_pengurus":["read","create","update","delete","export","import"],
    "pengusaha.pengusaha_kendaraan":["read","create","update","delete","export","import"],
    "pengusaha.pengusaha_pengemudi":["read","create","update","delete","export","import","approve","reject"],
      
    "karyawan.pengusaha_perusahaan":["read","update","export"],
    "karyawan.pengusaha_pengurus":["read","create","update","delete","export","import"],
    "karyawan.pengusaha_kendaraan":["read","create","update","delete","export","import"],
    "karyawan.pengusaha_pengemudi":["read","create","update","delete","export","import","approve","reject"],
    

    "karyawan-anggota.pengusaha_perusahaan":["read","export"],
    "karyawan-anggota.pengusaha_pengurus":["read","export"],
    "karyawan-anggota.pengusaha_pengemudi":["read","export"],
    "karyawan-anggota.pengusaha_kendaraan":["read","export"],
  }
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType
      };
    case THEME_COLOR_SELECTION:
      return {
        ...state,
        colorSelection: action.colorSelection
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,

      };
    default:
      return state;
  }
};

export default settings;
